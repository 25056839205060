import React, {useState, useEffect} from "react"
import PortableText from "@sanity/block-content-to-react"
import {Link} from "gatsby"
import {Layout, ModalLayout} from "../components/layout"
import {H3, P} from "../components/Typography"
import styled from "styled-components"
import client from './sanityClient'
import Seo from "../components/seo"
import * as Sections from "../components/SectionElements"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import * as Serializer from "../components/Serializer"

const NavigationButtons = styled.div`
  width: 100%;
  position: fixed;
  top: 0;

  @media ${props => props.theme.breakpoints.desktop} {
    max-width: 900px;
    width: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: ${props => props.theme.spacings.xSmall};
  top: ${props => props.theme.spacings.xxSmall};
  background-color: transparent;
  font-size: 40px;
  font-weight: 200;
  line-height: 0.5;

  > a {
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
  }

  @media ${props => props.theme.breakpoints.desktop} {
    // right: ${props => props.theme.spacings.section};
    font-size: 60px;
  }
`

const BackButton = styled.button`
  position: absolute;
  right: 50px;
  top: ${props => props.theme.spacings.xxSmall};
  background-color: transparent;
  font-size: 35px;
  font-weight: 200;
  line-height: 0.7;

  > a {
    padding: 0px;
    border-radius: 0;
    background-color: transparent;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
  }

  @media ${props => props.theme.breakpoints.desktop} {
    right: 70px;
    font-size: 60px;
    font-weight: 200;
    line-height: 0.6;
  }
`
const ModuleTitle = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};

    :hover {
      background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    }
  }
`

const Subtitle = styled.h1`
  font-size: 18px;
  line-height: 1.2;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
  padding-top: ${props => props.theme.spacings.xxSmall};
  font-weight: 400;
  color: ${props => props.color ? props.color : props.theme.colors.secondary};
  padding-bottom: ${props => props.theme.spacings.xxxSmall};

  @media ${props => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`
const ColoredSpan = styled.span`
  font-weight: 400;
  background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  padding: 2px 5px;
  border-radius: 5px;
  box-decoration-break: clone;
  line-height: 1.8;
  color: ${props => props.color ? props.color : props.theme.colors.foreground};
`
const LecturerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
`
const Lecturer = styled(P)`
  display: inline;
  margin-top:-.15em;
  > span {
    line-height: 1.7;
    // background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.grey1};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const LectureMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
`
const NbHours = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    padding: 2px 5px;
    border-radius: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.background};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const Ects = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.background};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const TextBlock = styled(PortableText)`
  >p:last-child {
    padding-bottom: 0 !important;
  }
`

const Cours = ({pageContext}) => {

    // console.log(pageContext.moduleId)

    const [postData, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
 
    useEffect(() => {
      let mounted = true
      setIsLoading(true);
      client
        .fetch(
          `*[ _type == "module" && _id == $moduleId]{
                _id,
                slug {current},
                title,
                lecture[_key==$coursId] {
                    _key,
                    title,
                    nbHours,
                    ects,
                    description,
                    references,
                    lecturer[]->
                }
            }`, {moduleId:pageContext.moduleId, coursId:pageContext.coursId}
        ).then((data) => setPost(data)).then((data) => setIsLoading(false))
        // .catch(console.error);
      return function cleanup() {
        mounted = false
        setPost(null)
      }
    }, [setPost, pageContext.coursId, pageContext.moduleId])
  
    const moduleSlug = pageContext.slug

    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
              <>
                  {modal ? (
                    isLoading ? (
                      <>
                      <Seo/>
                      <NavigationButtons>
                        <CloseButton><Link to={closeTo} state={{noScroll: true}}>&#215;</Link></CloseButton>
                        <BackButton><Link to={"/"+moduleSlug} state={{modal: true}}>&#8592;</Link></BackButton>
                      </NavigationButtons>
                      <ModalLayout>
                      <Sections.CenteredSection>
                        <P context="bigtext">Chargement...</P>
                      </Sections.CenteredSection>
                      </ModalLayout>
                      </>
                    ) : (
                      <>
                      <Seo title={postData[0].lecture[0].title}/>
                      <NavigationButtons>
                        <CloseButton><Link to={closeTo} state={{noScroll: true}}>&#215;</Link></CloseButton>
                        <BackButton><Link to={"/"+moduleSlug} state={{modal: true}}>&#8592;</Link></BackButton>
                      </NavigationButtons>
                      <ModalLayout>
                        <Sections.CenteredSection>
                          <ModuleTitle context="smalltext"><span><Link to={"/"+moduleSlug} state={{modal: true}}>{postData[0].title}</Link></span></ModuleTitle>
                          <Subtitle><ColoredSpan>{postData[0].lecture[0].title}</ColoredSpan></Subtitle>
                          <LecturerWrapper>
                            {postData[0].lecture[0].lecturer.map((prof, i) => {
                              return (<Lecturer context="nonblock" key={i}><span>{prof.firstname} {prof.surname}</span></Lecturer>)
                            })}
                          </LecturerWrapper>
                          <LectureMeta>
                            <NbHours context="smalltext"><span>{postData[0].lecture[0].nbHours}h</span></NbHours>
                            <Ects context="smalltext"><span>{postData[0].lecture[0].ects}ECTS</span></Ects>
                          </LectureMeta>
                        </Sections.CenteredSection>
                        <Sections.CenteredSection>
                          <TextBlock
                            blocks={postData[0].lecture[0].description}
                            serializers = {Serializer.block}
                          />
                        </Sections.CenteredSection>
                        {postData[0].lecture[0].references ? (
                          <Sections.CenteredSection>
                            <H3>Bibliographie</H3>
                            <TextBlock
                              blocks={postData[0].lecture[0].references}
                              serializers = {Serializer.block}
                            />
                          </Sections.CenteredSection>
                        ):(
                          <>
                          </>
                        )}
                      </ModalLayout>
                      </>
                    )
                  ) : (
                    isLoading ? (
                      <>
                      <Seo/>
                      <Layout homebutton={true}>
                        <Sections.CenteredSection>
                          <P context="bigtext">Chargement...</P>
                        </Sections.CenteredSection>
                      </Layout>
                      </>
                    ) : (
                      <>
                      <Seo title={postData[0].lecture[0].title}/>
                      <Layout homebutton={true}>
                        <Sections.CenteredSection>
                          <ModuleTitle context="smalltext"><span><Link to={"/"+moduleSlug}>{postData[0].title}</Link></span></ModuleTitle>
                          <Subtitle><ColoredSpan>{postData[0].lecture[0].title}</ColoredSpan></Subtitle>
                          <LecturerWrapper>
                            {postData[0].lecture[0].lecturer.map((prof, i) => {
                              return (<Lecturer context="nonblock" key={i}><span>{prof.firstname} {prof.surname}</span></Lecturer>)
                            })}
                          </LecturerWrapper>
                          <LectureMeta>
                            <NbHours context="smalltext"><span>{postData[0].lecture[0].nbHours}h</span></NbHours>
                            <Ects context="smalltext"><span>{postData[0].lecture[0].ects}ECTS</span></Ects>
                          </LectureMeta>
                        </Sections.CenteredSection>
                        <Sections.CenteredSection>
                          <TextBlock
                            blocks={postData[0].lecture[0].description}
                            serializers = {Serializer.block}
                          />
                        </Sections.CenteredSection>
                        {postData[0].lecture[0].references ? (
                          <Sections.CenteredSection>
                            <H3>Bibliographie</H3>
                            <TextBlock
                              blocks={postData[0].lecture[0].references}
                              serializers = {Serializer.block}
                            />
                          </Sections.CenteredSection>
                        ):(
                          <>
                          </>
                        )}
                      </Layout>
                      </>
                    )
                  )}
              </>
            )}
        </ModalRoutingContext.Consumer>
        )
};

export default Cours